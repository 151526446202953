<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Logs</page-title>

      <div class="ml-4 mt-1 mb-2">
        
      </div>
    </v-layout>
    <v-layout>
      <page-subtitle>View application logs.</page-subtitle>
    </v-layout>

  </div>
</template>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';

export default {
  components: {
    PageSubtitle,
    PageTitle,
  }
}

</script>